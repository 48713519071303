import React from 'react';
import '../styles/Bottom.css';
import SocialLinks from './SocialLinks';
import ThemeSwitch from './ThemeSwitch';

const Bottom = () => {
  return (
    <div className="bottom">
      <SocialLinks />
      <ThemeSwitch />
    </div>
  );
};

export default Bottom;
