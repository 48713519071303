import React, { useEffect, useState } from 'react';
import SocialLink from './SocialLink';
import { collection, getDocs } from 'firebase/firestore';  // Import Firestore methods
import { database } from './firebase';  // Import the Firestore instance
import '../styles/SocialLinks.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab)
library.add(fas)

const SocialLinks = () => {
  const [socialLinks, setSocialLinks] = useState([]);

  // Fetch data from your backend using the `databases.listDocuments` method
  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const querySnapshot = await getDocs(collection(database, "social_links"));
        const links = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSocialLinks(links.sort((a, b) => a.order - b.order));
      } catch (error) {
        console.error("Error fetching social links:", error);
      }
    };

    fetchSocialLinks();
  }, []);
  return (
    <div className="social-links">
      {socialLinks.length > 0 ? (
        socialLinks.map((socialLink) => (
          <SocialLink
            key={socialLink.id}
            icon={socialLink.icon}
            imageUrl={socialLink.image}
            title={socialLink.title}
            link={socialLink.link}
            backgroundColor={socialLink.background_color}
          />
        ))
      ) : (
        <p>Loading social links...</p>  // Show a loading message while data is being fetched
      )}
    </div>
  );
};

export default SocialLinks;