import React from 'react';
import '../styles/Content.css';
import ProfileImage from './ProfileImage';
import Description from './Description';

const Content = () => {
  return (
    <div className="content">
       <ProfileImage />
       <Description />
    </div>
  );
};

export default Content;
