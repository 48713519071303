// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAdFwH40b7khMxtB3CSdS1dMUN_7k1MO40",
  authDomain: "ipavlidakis-website.firebaseapp.com",
  projectId: "ipavlidakis-website",
  storageBucket: "ipavlidakis-website.appspot.com",
  messagingSenderId: "1057308933202",
  appId: "1:1057308933202:web:10d99a82a6a7263a56738b",
  measurementId: "G-7PHNX2RRZB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getFirestore(app);

export { analytics, database };