import React from 'react';
import '../styles/Content.css';

const Top = () => {
  return (
    <div className="top">
    </div>
  );
};

export default Top;
