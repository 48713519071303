import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function setVh() {
  // Get the viewport height and multiply by 1% to get a value for 1vh
  let vh = window.innerHeight * 0.01;
  // Set the value in the --vh custom property to be used in CSS
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

// Initial execution
setVh();

// Recalculate on window resize
window.addEventListener('resize', setVh);