import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import '../styles/SocialLink.css';
import { logEvent } from 'firebase/analytics';
import { analytics } from './firebase';  // Import the initialized analytics instance

const SocialLink = ({ icon, imageUrl, title, link, backgroundColor }) => {
  const handleClick = () => {
    logEvent(analytics, 'social_link_click', {
      social_network: title,  // Track the name of the social network (e.g., 'GitHub')
      link_url: link          // Track the link URL
    });
  };
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="social-link"
      onClick={handleClick}  // Trigger the click tracking
    >
      <div
        className="social-icon-container"
        style={{ backgroundColor }} // Apply background color to the bubble
      >
        {icon ? (
          <FontAwesomeIcon icon={icon} className="social-icon" />  // Render FontAwesome icon
        ) : (
          <img src={imageUrl} alt={title} className="social-icon" />  // Render image if no icon is provided
        )}
      </div>
      {title && <span className="social-title">{title}</span>}
    </a>
  );
};

export default SocialLink;